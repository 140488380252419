<mat-sidenav-container fullscreen>
  <mat-sidenav-content>
    <div class="contianermobile">
      <div class="row setmainhead">
        <mat-toolbar class="mat-toolbar-row">
          <div class="left-col">
            <mat-icon (click)="backClicked()" class="back">keyboard_arrow_left</mat-icon>
          </div>
          <div class="col">
            <h2 class="title">Passbook</h2>
          </div>
          <div class="col text-right">
            <mat-icon (click)="gonotification()">notifications</mat-icon>
          </div>
        </mat-toolbar>
      </div>
      <div class="passbookwrap">
        <div class="row password" *ngFor="let tdata of transactions">
          <div class="col-3">
            <div class="text-success">
              <!-- <img  src="https://acepunt.kushubmedia.com/uploads/passbook/{{tdata?.image}}" class="iconzo1"> -->
              <img *ngIf="!tdata.sites" src="assets/logo/logo_1.png" class="iconzo1">
              <!-- <i class="material-icons iconzo1">account_balance_wallet</i> -->
              <!-- <img src="https://acepunt.kushubmedia.com/uploads/payments/paytm_upi.png" class="setback"> -->
              <img *ngIf="tdata.sites" src="https://acepunt.kushubmedia.com/uploads/sites/{{tdata?.sites?.image}}"
                class="setback">
            </div>
          </div>
          <div class="col-8 setcolumn" (click)="openmodal(passbookdetaile ,tdata)">
            <b class="p-0" *ngIf="tdata.type!='Withdrawal'">
              <span Class="settext">{{tdata?.type}} <span> To {{tdata?.to}}</span></span>
              <span style="float:right;color: #fff;letter-spacing: 1px;font-weight: 400;">{{tdata?.amount}}</span></b>
            <b class="p-0" *ngIf="tdata.type=='Withdrawal'">
              <span Class="settext">{{tdata?.type}} <span> From {{tdata?.to}}</span></span>
              <span style="float:right;color: #fff;letter-spacing: 1px;font-weight: 400;">{{tdata?.amount}}</span></b>
            <p class="setremark">
              <span>{{tdata?.createdAt|date:'medium'}} <span style="float:right"
                  [ngClass]="tdata?.status == 'Approved' ? 'setlimit' : 'limitdanger'">{{tdata?.status}}</span></span><br>
              <span>Remark : {{tdata?.remarks}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>



<!-- Passbook detaile -->

<ng-template #passbookdetaile>
  <div class="modal-header model_header bg-warning">
    <h4 class="modal-title">Transaction Details</h4>
    <a type="button" class="close" data-dismiss="modal">
      <mat-icon (click)="modalRef.hide()">close</mat-icon>
    </a>
  </div>

  <div class="modal-body" *ngIf="pasbooketails">
    <div *ngIf="pasbooketails?.type!='Withdrawal'">
      <h4 class="settitle">{{pasbooketails?.type}} To {{pasbooketails?.to}} <span
          [ngClass]="pasbooketails?.status == 'Approved' ? 'setlimit' : 'limitdanger'">{{pasbooketails?.status}}</span>
      </h4>
    </div>
    <div *ngIf="pasbooketails?.type=='Withdrawal'">
      <h4 class="settitle">{{pasbooketails?.type}} From {{pasbooketails?.to}} <span
          [ngClass]="pasbooketails?.status == 'Approved' ? 'setlimit' : 'limitdanger'">{{pasbooketails?.status}}</span>
      </h4>
      <button class="setcancel" (click)="hide=!hide"
        *ngIf="pasbooketails?.type=='Withdrawal' && pasbooketails?.status=='Pending'">Cancel Request</button>
      <button id="openModalButton" [hidden]="true" (click)="cancel(success)">test</button>
    </div>
    <div class="cancelform" *ngIf="hide && pasbooketails?.status == 'Pending'">
      <form class="example-form" [formGroup]='cancelwithdrawForm' (ngSubmit)='cancelwithdraw()'>
        <mat-form-field appearance="outline" class="setfield">
          <mat-label class="setlabel">Please give a reason</mat-label>
          <input matInput type="text" formControlName='reason'>
          <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        </mat-form-field>
        <div class="submit-btn">
          <button mat-raised-button [disabled]="loginButtonDisable"><span class="setspan">Cancel
              Withdrawal</span></button>
        </div>
      </form>
    </div>
    <mat-list>
      <div>
        <h4 class="modal-head">Details</h4>
      </div>
      <div class="row">
        <div class="col-5">UserName</div>
        <div class="col-7">{{pasbooketails?.username}}</div>
      </div>
      <div class="row">
        <div class="col-5">Name</div>
        <div class="col-7">{{pasbooketails?.name}}</div>
      </div>
      <div class="row">
        <div class="col-5">Coins</div>
        <div class="col-7">{{pasbooketails?.amount| currency:"&#8377;"}}</div>
      </div>
      <div class="row">
        <div class="col-5">PaymentType</div>
        <div class="col-7">{{pasbooketails?.paymentType}}</div>
      </div>
      <div *ngIf="pasbooketails.paymentId">
        <div class="row" *ngIf="pasbooketails.paymentId.type!='bank'">
          <div class="col-5">Upi</div>
          <div class="col-7">{{pasbooketails?.paymentId?.upi}}</div>
        </div>
        <div class="row" *ngIf="pasbooketails.paymentId.type=='bank'">
          <div class="col-5">Account No.</div>
          <div class="col-7">{{pasbooketails?.paymentId?.accnumber}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-5">Reference No.</div>
        <div class="col-7">{{pasbooketails?.refrenceNo}}</div>
      </div>
      <div class="row">
        <div class="col-5">Request Date</div>
        <div class="col-7">{{pasbooketails?.createdAt|date:'medium'}}</div>
      </div>
      <div class="row">
        <div class="col-5">Approved Date</div>
        <div class="col-7">{{pasbooketails?.updatedAt|date:'medium'}}</div>
      </div>
      <div class="row">
        <div class="col-5">ApprovedBy</div>
        <div class="col-7">{{pasbooketails?.approvedBy}}</div>
      </div>
    </mat-list>

    <div *ngIf="detaile?.image.length!=0">
      <h4 class="settitle">Payment Screenshot</h4>
      <img src="https://acepunt.kushubmedia.com/uploads/screenshot/{{pasbooketails?.image}}" class="imageset">
    </div>

    <div *ngIf="pasbooketails?.status == 'Approved'">
      <h4 class="settitle">Transaction Receipt</h4>
    </div>

    <mat-list class="setmat" *ngIf="pasbooketails?.status == 'Approved'">

      <div class="text-center sethead">
        <img *ngIf="user.manager=='OSGCLUB'" src="assets/logo/clubosg.png" class="setlogo">
        <img *ngIf="user.manager=='PRTCLUB'" src="assets/logo/clubprt.png" class="setlogo1">
        <img *ngIf="user.manager=='AOBCLUB'" src="assets/logo/clubaob.png" class="setlogo2">
        <img *ngIf="user.manager=='FERRARICLUB'" src="assets/logo/ferrariclubb.png" class="setlogo3">
        <img *ngIf="user.manager=='DLCLUB'" src="assets/logo/dlexch.png" class="setlogo4">
        <img *ngIf="user.manager=='ALL'" src="assets/logo/allin1punt.png" class="setlogo4">
        <!-- <img *ngIf="user.manager=='OSGAMIT'" src="assets/logo/clubosg.png" class="setlogo">
        <img *ngIf="user.manager=='MAHICLUB'" src="assets/logo/clubosg.png" class="setlogo"> -->
        <!-- <img *ngIf="user.manager=='BETX002'" src="assets/logo/clubosg.png" class="setlogo"> -->
        <!-- <img *ngIf="user.manager=='OSGARJUN'" src="assets/logo/clubosg.png" class="setlogo"> -->
        <img
          *ngIf="user.manager!='DLCLUB' && user.manager!='dlexch' && user.manager!='PRTCLUB' && user.manager!='AOBCLUB' && user.manager!='OSGCLUB' "
          src="assets/logo/paisaexch.png" class="setlogo5">
      </div>

      <div class="text-center">
        <h4 class="trantion">Transaction Receipt</h4>
        <h5 class="setmodalhad">Payment Amount</h5>
        <h5>{{pasbooketails?.amount| currency:"&#8377;"}}</h5>
        <h5 [ngClass]="pasbooketails?.status == 'Approved' ? 'setlimit1' : 'limitdanger1'">Successfully Transferred</h5>
      </div>
      <div class="setmodalrow">
        <span style="color: #3d3d3d;">Name</span>
        <h5 class="setmodalhad">{{pasbooketails?.name}}</h5>
      </div>
      <div *ngIf="pasbooketails.paymentId">
        <div class="setmodalrow" *ngIf="pasbooketails.paymentId.type!='bank'">
          <span style="color: #3d3d3d;">Payment Mode</span>
          <!-- <span style="color: #3d3d3d;">UPI No.</span> -->
          <h5 class="setmodalhad">{{pasbooketails?.paymentId?.upi}}</h5>
        </div>
        <div class="setmodalrow" *ngIf="pasbooketails.paymentId.type=='bank'">
          <span style="color: #3d3d3d;">Payment Mode</span>
          <!-- <span style="color: #3d3d3d;">Credit Account</span> -->
          <h5 class="setmodalhad">{{pasbooketails?.paymentId?.accnumber}}</h5>
        </div>
      </div>
      <div *ngIf="!pasbooketails.paymentId">
        <div class="setmodalrow">
          <span style="color: #3d3d3d;">Payment Mode</span>
          <h5 class="setmodalhad">{{pasbooketails?.paymentType}}</h5>
        </div>
      </div>
      <div class="setmodalrow">
        <span style="color: #3d3d3d;">UTR No.</span>
        <h5 class="setmodalhad">{{pasbooketails?.utrId}}</h5>
      </div>
      <div class="setmodalrow">
        <span style="color: #3d3d3d;">Paid Date</span>
        <h5 class="setmodalhad">{{pasbooketails?.updatedAt|date:'medium'}}</h5>
      </div>
      <div>
        <h4 class="modalfoot" *ngIf="pasbooketails?.status == 'Approved'">Your Payment has been <span
            style="color: #4CAF50;">Completed Successfully</span></h4>
        <!-- <h4 class="modalfoot" *ngIf="pasbooketails?.status != 'Approved'">Your Request has been <span style="color: #e33e36;">{{pasbooketails?.status}}</span></h4> -->
      </div>

    </mat-list>

  </div>
</ng-template>


<ng-template #success>
  <div class="modal-body">
    <mat-list class="setmat1">
      <div class="text-right"><a type="button" class="close1" data-dismiss="modal">
          <mat-icon (click)="modalRefcancel.hide()">close</mat-icon>
        </a></div>
      <div class="text-center">
        <mat-icon class="setback1">done</mat-icon>
        <!-- <img src="assets/images/success.svg" /> -->
        <h5 class="setamount">{{depositdata?.doc?.amount | currency:"&#8377;"}}</h5>
        <h4 class="trantion1" *ngIf="cancelmessage">{{cancelmessage?.message}}</h4>
        <button class="backbutton1" (click)="modalRef.hide();modalRefcancel.hide()" [routerLink]="['/wallet/']">Back To
          Home</button>
      </div>
    </mat-list>

  </div>
</ng-template>