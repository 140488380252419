<header>
    <div class="header">
        <mat-toolbar class="mat-toolbar-row contianermobile">
            <div class="left-col">
                <span (click)="inplay();"><img src="assets/logo/{{logo}}.png" [ngStyle]="{'max-width':getWidth()}" alt="{{logo}}"></span>
            </div>
            <span class="div-spacer"></span>
            <div class="right-col">
                <ng-container *ngIf="loginButtnStatus">
                   <button mat-stroked-button class="balance">
                        <mat-icon (click)="RefreshBal();">refresh</mat-icon> Ba: <br>
                        <span> {{walletBalance| number : '0.0-2'}}</span>
                   </button>
                  <button [routerLink]="['/my-bet']" mat-stroked-button >
                     <mat-icon>exposure</mat-icon> Ex :<br>
                     <span> {{exposure| number : '0.0-2'}}</span>
                   </button>
                </ng-container>
                <ng-container *ngIf="!loginButtnStatus">
                 <button *ngIf="reg_symbol && this.logo!= 'paisaexch'" mat-stroked-button [routerLink]="['/register/nocode']" style="color:#fd7423;">
                    <mat-icon>how_to_reg</mat-icon>Join Now<br>
                 </button>
                 <button mat-stroked-button [routerLink]="['/login']" style="color:#fd7423;">
                    <mat-icon>login</mat-icon> login<br>
                 </button>
                </ng-container>
            </div>
        </mat-toolbar>
    </div>
</header>



